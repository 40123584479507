<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import GenericError from '~/components/user-interface/generic-error.vue';
import { trackingMethods } from '~~/plugins/analytics.client';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputText from 'primevue/inputtext';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ExternalIcon from '../design-system/icons/ExternalIcon.vue';
import CategoryGrid from '~/components/category/category-grid.vue';
import type { IVerticalElement } from '~/interfaces/dto/games/response';

const { $gameService } = useServices();
const gameStore = useGameStore();

const activeIndex = ref<number>(0);
const searchText = ref<string>('');
const searching = ref<boolean>(false);

const errorMessage = ref<string>('sorry-cant-find-results');

const results = ref<IVerticalElement[]>([]);
const verticals = ref<string[]>();

const search = ref<HTMLElement>();

const currentVertical = computed<IVerticalElement>(
  () => results.value[activeIndex.value],
);

function reset() {
  searchText.value = '';
  applyDefaults();
}

// make an api call and get data
async function fetchSearchedGames(queryParam: string) {
  const query = queryParam.trim();
  searching.value = true;
  if (!query) {
    // send GA tracking method for Empty Search Query
    await trackingMethods.searchQueryEmpty();
    errorMessage.value = 'please-enter-search';
    searching.value = false;
  } else {
    // send GA tracking method with Search Query
    await trackingMethods.searchQuery(query);
    $gameService
      .fetchSearchedGames({
        search: query,
        languageCode: 'en-US',
        channel: 'WebDesktop',
        skip: 0,
        limit: 0,
        currency: 'USD',
      })
      .then(
        (data) => {
          searching.value = false;
          if (data.total < 1) {
            errorMessage.value = 'no-results-found';
            results.value = [];
            return;
          }

          results.value = data.verticals;
          verticals.value = data.verticals.map((item) => item.vertical);

          // send GA tracking method with Search Results Success
          trackingMethods.searchResultSuccess(data.total);
        },
        (err) => {
          console.error(err);
          // send GA tracking method with Search Results Failure
          trackingMethods.searchFailure(err.message);
          fetchSearchedGames('featured');
          searching.value = false;
        },
      );
  }
}

const applyDefaults = () => {
  const defaults = [];

  if (!!gameStore.returnRecommended?.length)
    defaults.push({
      count: gameStore.returnRecommended?.length,
      data: gameStore.returnRecommended,
      vertical: 'Recommended',
    });

  if (!!gameStore.returnWinnersCircle?.hotGames?.length)
    defaults.push({
      count: gameStore.returnWinnersCircle?.hotGames?.length,
      data: gameStore.returnWinnersCircle?.hotGames.map(({ game }) => game),
      vertical: 'Hot Games',
    });
  if (!!gameStore.returnFavorites?.length)
    defaults.push({
      count: gameStore.returnFavorites?.length,
      data: gameStore.returnFavorites,
      vertical: 'Favorites',
    });

  results.value = defaults;
};

onMounted(() => {
  (document.querySelector('#search') as HTMLInputElement).focus();
  applyDefaults();
});

//live search
watch(
  () => searchText.value,
  (newText, oldText) => {
    if (searchText.value.length > 2 && newText.length >= oldText.length) {
      debounceSearch();
    }
  },
);

const debounceSearch = debounce(() => {
  fetchSearchedGames(searchText.value);
}, 500);

function debounce(func, timeout) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
</script>

<template>
  <div class="h-full">
    <div class="p-2">
      <InputGroup>
        <InputGroupAddon class="border-r">
          <ExternalIcon
            class="ml-2 w-6 mx-auto"
            icon-name="search-magnifying"
          />
        </InputGroupAddon>
        <InputText
          id="search"
          class="dark:text-white"
          ref="search"
          v-model="searchText"
          :placeholder="$t(`search-games`)"
          :label="$t(`search-games`)"
        />
        <InputGroupAddon
          v-if="!!searchText"
          class="bg-primary-layer text-base"
          @click="reset"
        >
          <div class="rounded-full bg-primary-layer-alternative p-1">
            <XMarkIcon class="w-5" />
          </div>
        </InputGroupAddon>
      </InputGroup>
    </div>
    <nav
      v-if="results.length > 1"
      class="flex items-center pl-2 pt-1 pb-2 gap-1"
      style="overflow: auto"
    >
      <div v-for="(item, index) in results" :key="index">
        <Button
          class="text-sm md:text-md w-fit"
          style="block-size: fit-content"
          rounding="md"
          :type="activeIndex === index ? 'primary' : 'transparent'"
          @click="[(activeIndex = index)]"
        >
          {{
            $t(`vertical.${item.vertical.replaceAll(' ', '-').toLowerCase()}`)
          }}
        </Button>
      </div>
    </nav>
    <div class="p-2">
      <div v-if="!!searchText" class="flex justify-between">
        <p class="font-bold text-lg text-base-priority">
          {{ $t('results') }}
        </p>
        <Button class="text-xs" type="tertiary" @click="reset">
          {{ $t('reset') }}
        </Button>
      </div>
      <div class="overflow-auto h-full">
        <LazyUserInterfaceGenericLoader v-if="searching" />
        <CategoryGrid
          v-if="!searching && currentVertical?.data?.length"
          in-modal
          :games="currentVertical?.data"
          lazy-load-more
          :max-size="currentVertical?.count"
        />
        <GenericError
          v-else-if="!searching && !results.length && !!search"
          state="danger"
          class="mb-3"
        >
          {{ $t(errorMessage) }}
        </GenericError>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
